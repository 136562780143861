<template>
  <div class="footer-info">
    <div class="w-1200">
      <div class="left">
        <div class="img">
          <img :src="require('@/assets/images/footer.png')" alt="" />
          <!-- <SvgIcon iconClass="footer"/> -->
        </div>

        <div class="tip">
          电池之家-拍卖平台，是电池之家综合电池交易服务平台倾力打造的电池竞拍交易服务平台。
        </div>

        <div class="tel">
          <span class="wz">客服热线</span>
          <span class="tele">010-67888337</span>
        </div>
      </div>
      <div class="right">
        <div class="yqlj">友情链接</div>
        <div class="pub" @click="to('https://www.bitnei.cn/')">
          北京理工新源官网
        </div>
        <div class="pub" @click="to('http://www.ndanev.com/')">
          新能源汽车国家大数据联盟官网
        </div>
        <div class="pub" @click="to('https://www.bit.edu.cn/')">
          北京理工大学
        </div>
        <div class="pub" @click="to('http://battery.f139.com/')">富宝资讯</div>
      </div>
    </div>

    <div class="beianhao">
      ICP备案/许可证号: 京ICP备2021029142号/京B2-20240742
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      lastYear: new Date().getFullYear() || "",
    };
  },
  methods: {
    to(url) {
      window.open(url);
    },
  },
};
</script>
<style lang="less" scoped>
.svg-icon {
  width: 240px !important;
  height: 47px !important;
}
.footer-info {
  width: 100%;
  height: 300px;
  box-sizing: border-box;
  background: #272934;
  z-index: 999;
  // position: absolute;
  // bottom: 0;
  .beianhao {
    font-weight: 400;
    font-size: 12px;
    color: #ffffff99;
    text-align: center;
    margin-top: 12px;
  }
  .w-1200 {
    width: 1200px;
    margin: 0 auto;
    border-bottom: 1px solid #595959;
    padding-top: 50px;
    padding-bottom: 20px;
    box-sizing: border-box;
    display: flex;
    .left {
      width: 80%;
      .img {
        width: 180px;
        height: 50px;
        img {
          height: 100%;
        }
      }
      .tip {
        font-weight: 400;
        font-size: 14px;
        color: #ffffff99;
        margin-top: 20px;
      }
      .tel {
        margin-top: 30px;
        .wz {
          font-weight: 400;
          font-size: 16px;
          color: #ffffff;
          margin-right: 20px;
        }
        .tele {
          font-weight: 500;
          font-size: 24px;
          color: #ffffff;
        }
      }
    }
    .right {
      width: 20%;
      // padding-left: 50px;
      box-sizing: border-box;
      // height: 100px;
      .yqlj {
        // font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        margin-bottom: 24px;
      }
      .pub {
        font-weight: 400;
        font-size: 14px;
        color: #ffffffa6;
        margin-top: 12px;
        cursor: pointer;
      }
    }
  }
}
</style>
