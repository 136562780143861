<template>
  <el-dialog
    title=""
    :visible.sync="$store.state.user.dialogLogin"
    width="500px"
    :close-on-click-modal="false"
  >
    <div class="login-container">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="账号密码登录" name="mima" />
        <el-tab-pane label="验证码登录" name="checking" />
      </el-tabs>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        label-position="top"
        class="demo-ruleForm"
      >
        <el-form-item label="用户名" prop="username">
          <el-input
            clearable
            v-model.number="ruleForm.username"
            placeholder="请输入手机号"
          >
            <em slot="prefix">
              <SvgIcon iconClass="person" />
            </em>
          </el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password" v-if="activeName == 'mima'">
          <el-input
            clearable
            type="password"
            v-model="ruleForm.password"
            show-password
            placeholder="请输入密码"
          >
            <em slot="prefix"> <SvgIcon iconClass="password" /> </em
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <div class="flexBox">
            <el-input
              @keyup.enter="submitForm('ruleForm')"
              v-model="ruleForm.code"
              style="width: 270px; margin-right: 30px"
            >
              <em slot="prefix">
                <SvgIcon iconClass="yzm" />
              </em>
            </el-input>
            <div class="imgs" @click="getCode">
              <el-image
                style="height: 46px; cursor: pointer"
                :src="codeUrl"
                fit="contain"
              ></el-image>
            </div>
          </div>
        </el-form-item>

        <el-form-item
          label="短信验证码"
          prop="smsCode"
          v-if="activeName == 'checking'"
        >
          <div class="flexBox">
            <el-input
              @keyup.enter="submitForm('ruleForm')"
              v-model="ruleForm.smsCode"
              style="width: 280px; margin-right: 30px"
            >
              <em slot="prefix"> <SvgIcon iconClass="yzm" /> </em
            ></el-input>
            <el-button
              type="text"
              :disabled="countdown > 0"
              @click.prevent="getYzm"
            >
              {{ countdown > 0 ? `重新获取(${countdown}s)` : "点击获取验证码" }}
            </el-button>
          </div>
        </el-form-item>

        <el-form-item>
          <el-button
            type="primary"
            style="width: 100%; margin-top: 10px; font-size: 18px"
            @click.prevent="submitForm('ruleForm')"
            >立即登录</el-button
          >
        </el-form-item>
      </el-form>

      <div class="foot">
        <el-checkbox v-model="checked">记住密码</el-checkbox>
        <div class="right">
          <el-button type="text" @click="goBack(true)">注册</el-button>
          <el-button
            type="text"
            v-if="activeName == 'mima'"
            @click="goBack(false)"
            >忘记密码</el-button
          >
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import { captchaImage, getSmsCode } from "@/api/index.js";
import { encrypt, decrypt } from "@/utils/jsencrypt.js";
export default {
  name: "Login",
  data() {
    var checkPhone = (rule, value, callback) => {
      // 手机号验证
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3456789]\d{9}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };

    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    return {
      codeUrl: "",
      checked: false,
      countdown: 0,
      activeName: "mima",
      ruleForm: {
        password: "",
        code: "",
        username: "",
        smsCode: "",
        uuid: "",
      },
      rules: {
        password: [{ validator: validatePass, trigger: "blur" }],
        username: [{ validator: checkPhone, trigger: "blur" }],
        code: [{ message: "请输入验证码", trigger: "blur" }],
        smsCode: [{ message: "请输入手机验证码", trigger: "blur" }],
      },
    };
  },
  watch: {
    "$store.state.user.dialogLogin": {
      handler(newVal) {
        if (newVal) {
          this.getCode();
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.getCode();
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            let params = JSON.parse(JSON.stringify(this.ruleForm));
            params.username = encrypt(params.username + "");
            params.password = encrypt(params.password);
            await this.$store.dispatch("userLogin", params);
            this.$store.commit("SETLOGIN", false);
            this.$message.success("已登录");
            this.$store.dispatch("getUserInfo");
            this.$router.go();
          } catch (error) {
            this.$message.error(error);
            this.getCode();
          }
        }
      });
    },
    //
    getYzm() {
      if (!this.ruleForm.username) {
        return this.$message.error("请输入先输入手机号");
      }
      if (!this.ruleForm.code) {
        return this.$message.error("请输入先输入验证码");
      }

      let param = {
        mobile: this.ruleForm.username,
        uuid: this.ruleForm.uuid,
        code: this.ruleForm.code,
      };
      getSmsCode(param).then(({ code, msg }) => {
        if (code !== 200) {
          return this.$message.error(msg);
        } else {
          this.countdown = 60;
          const interval = setInterval(() => {
            if (this.countdown > 0) {
              this.countdown--;
            } else {
              clearInterval(interval);
            }
          }, 1000);
        }
      });
    },
    getCode() {
      captchaImage().then((res) => {
        this.codeUrl = "data:image/gif;base64," + res.img;
        this.ruleForm.uuid = res.uuid;
      });
    },
    handleClick() {
      this.$refs.ruleForm.resetFields();
    },
    goBack(type) {
      this.$store.commit("SETREGISTER", {
        dialog: true,
        zczh: type,
      });
      this.$store.commit("SETLOGIN", false);
    },
  },
  computed: {
    ...mapState({
      dialogLogin: (state) => state.user.dialogLogin,
    }),
  },
};
</script>

<style lang="less" scoped>
// /deep/.el-dialog__headerbtn{
//   top: 25px;
//   right: 40px;
// }
/deep/.el-input .el-input__clear {
  color: rgba(0, 0, 0, 0.6);
  font-size: 20px;
}
/deep/.el-dialog__headerbtn .el-dialog__close {
  color: rgba(0, 0, 0, 0.4);
}
/deep/.el-dialog__headerbtn {
  font-size: 24px;
}
/deep/.el-checkbox__label {
  color: rgba(0, 0, 0, 0.6);
}
/deep/.svg-icon {
  width: 20px !important;
  height: 20px !important;
}
/deep/.el-input--prefix .el-input__inner {
  padding-left: 40px;
  font-size: 16px;
}
/deep/.el-input__prefix {
  left: 12px;
}
/deep/ .el-tabs__item {
  font-size: 18px;
}
/deep/ .el-form--label-top .el-form-item__label {
  font-weight: 400;
  font-size: 16px;
  color: #000000d9;
}
/deep/ .el-dialog__header {
  padding-left: 40px;
}
/deep/ .el-dialog__title {
  width: 235px;
  height: 45px;
  display: block;
  background: url("../../assets/images/dczj_paimai.png");
  background-size: contain;
  background-repeat: no-repeat;
}
/deep/ .el-dialog__body{
  padding: 10px 20px 20px;
}
.login-container {
  padding: 0 20px;
  .flexBox {
    display: flex;
    align-items: center;
    .imgs {
      display: flex;
      align-items: center;
    }
  }
  /deep/ .el-form-item__label {
    padding-bottom: 0;
    height: 34px;
  }
  .foot {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .right {
    display: flex;
  }
}
</style>
