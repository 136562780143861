<template>
  <header class="header">
    <!-- 头部的第一行 -->
    <div class="left">
      <router-link class="logo" title="竞拍" to="/home">
        <img :src="require('@/assets/images/dczj_paimai.png')" alt="" />
        <!-- <SvgIcon iconClass="header" style="width: 182px; height: 31px" /> -->
      </router-link>
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
      >
        <el-menu-item index="/home" style="font-size: 16px">首页</el-menu-item>
        <el-menu-item index="/biddingProject" style="font-size: 16px"
          >拍卖项目</el-menu-item
        >
        <el-menu-item index="/Notice" style="font-size: 16px"
          >拍卖公告</el-menu-item
        >
        <el-menu-item index="/Rule" style="font-size: 16px"
          >规则说明</el-menu-item
        >
      </el-menu>
    </div>

    <div class="right">
      <div class="typeList">
        <p class="phone typeItem">
          <img
            src="@/assets/images/home/phone.png"
            alt=""
            style="width: 26px"
          /><span
            style="
              margin-left: 8px;
              color: rgba(0, 0, 0, 0.6);
              font-size: 20px;
              font-weight: 500;
            "
            >010-67888337</span
          >
        </p>
        <p class="typeItem table" @click="toWorkSpace()">
          <span style="margin-left: 10px; margin-right: 6px; font-size: 16px"
            >我的工作台</span
          >
          <img
            src="@/assets/images/home/right.png"
            alt=""
            style="width: 20px"
          />
        </p>
        <div class="userButton" v-if="userInfo.userName">
          <el-dropdown>
            <div class="dropdown-link">
              <el-badge :value="total" class="badges" :hidden="total == 0">
                <img
                  src="@/assets/images/home/masge.png"
                  alt=""
                  style="width: 30px; height: 30px; cursor: pointer"
                />
              </el-badge>
            </div>
            <el-dropdown-menu slot="dropdown" v-if="dataList.length">
              <el-dropdown-item v-for="item in dataList" :key="item.noticeId">
                <div class="itemTit" @click="$router.push('/MyMessage')">
                  <span> {{ item.noticeTitle }}</span>
                  <span> {{ item.readTime }}</span>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown>
            <div class="dropdown-link">
              <img
                v-if="userInfo.sex == '0'"
                src="@/assets/images/girl.png"
                alt=""
                class="imgLink"
              />
              <img
                v-else
                src="@/assets/images/boy.png"
                class="imgLink"
                alt=""
              />
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <div @click="$router.push('/AccountSettings')">账号设置</div>
              </el-dropdown-item>
              <el-dropdown-item>
                <div @click="logout">退出登录</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="userButton" v-else>
          <p class="buttonItem" @click="Registertrue">注册</p>
          <p class="buttonItem color" @click="Logintrue">登录</p>
        </div>
      </div>
      <Login />
      <Register />
    </div>
  </header>
</template>

<script>
import noticeBus from "./noticeBus";
import { mapState } from "vuex";
import { noticeList } from "@/api/message";
import Login from "@/components/Login/index.vue";
import Register from "@/components/Register/index.vue";
export default {
  name: "",
  components: {
    Login,
    Register,
  },
  data() {
    return {
      dataList: [],
      total: 0,
    };
  },
  mounted() {
    // 监听事件总线的消息
    noticeBus.$on("message", () => {
      this.getNoticeList();
    });
    this.getNoticeList();
  },
  methods: {
    //退出登录
    logout() {
      //退出登录的请求
      this.$store.dispatch("userLogout");
      this.$router.push({
        path: "/home",
      });
    },
    handleSelect(key) {
      this.$router.push(key);
    },
    Registertrue() {
      this.$store.commit("SETREGISTER", {
        dialog: true,
        zczh: true,
      });
    },
    Logintrue() {
      this.$store.commit("SETLOGIN", true);
    },
    toWorkSpace() {
      if (this.userInfo.userName) {
        this.$router.push({
          path: "/workSpace",
        });
      } else {
        this.$store.commit("SETLOGIN", true);
      }
    },
    getNoticeList() {
      console.log("hader调用");
      let param = {
        userId: this.userInfo.userId,
        noticeType: "1",
        isRead: "0",
      };
      noticeList(param).then((res) => {
        if (res.code == 200) {
          this.dataList = res.rows;
          this.total = res.total;
        }
      });
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
    activeIndex() {
      let index = this.$route.path.split("/")[1];
      return "/" + index;
    },
  },
};
</script>

<style scoped lang="less">
.el-menu.el-menu--horizontal {
  border: none;
}
.el-menu--horizontal > .el-menu-item.is-active {
  font-weight: 600;
}
.el-menu--horizontal > .el-menu-item {
  color: rgba(0, 0, 0, 85%);
}
.header {
  display: flex;
  align-items: center;
  background-color: #fff;
  height: 60px;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0;
  width: 100%;
  box-shadow: 0 1px 0 0 #0000000f;
  .left {
    display: flex;
    align-items: center;
    .el-menu--horizontal > .el-menu-item {
      height: 60px;
      width: 106px;
      text-align: center;
    }
    .logo {
      width: 180px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        // width: 100%;
        height: 100%;
        // height: 100%;
        // margin: 0 60px 0 45px;
      }
    }
  }
  .right {
    .typeList {
      overflow: hidden;
      display: flex;
      align-items: center;
      .typeItem {
        display: flex;
        align-items: center;
        margin-left: 40px;
        img {
          width: 30px;
        }
      }
      .phone {
        font-size: 16px;
        color: #666;
      }
      .table {
        height: 60px;
        width: 150px;
        text-align: center;
        background: url("../../assets/images/home/tableBg.png");
        background-size: cover;
        cursor: pointer;
      }
      .userButton {
        width: 200px;
        display: flex;
        align-items: center;
        .buttonItem {
          height: 60px;
          line-height: 60px;
          width: 50%;
          text-align: center;
          font-size: 16px;
          color: #333;
          cursor: pointer;
        }

        .color {
          background-color: #0dac65;
          color: #fff;
        }
        .bigIcon {
          font-size: 10px;
          color: #999;
          cursor: pointer;
        }
        .imgLink {
          margin-top: 4px;
          width: 34px;
          height: 34px;
          cursor: pointer;
        }
        .dropdown-link {
          margin-top: 6px;
          text-align: center;
          .badges {
            margin-top: 2px;
            width: 100px;
            /deep/.el-badge__content.is-fixed {
              right: 48px;
            }
          }
        }
      }
    }
  }
}
</style>
